import { useDispatch } from 'react-redux';
import { useStaffCreateMutation } from 'store/api/clinics/clinicApi';
import { Button, Input, DatePicker, RadioButton } from 'components';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { ChangeEvent } from 'react';
import { setMessage } from 'store/slices/message';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { Roles } from 'types/Roles';
import { RadioGroup } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from 'hooks/useAppSelector';
import { AuthFieldType } from 'types/enums/AppointmentEnum';
import { emailValidator, phoneValidator } from 'constants/constants';
import ReactInputMask from 'react-input-mask';

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  workingStartDate: Dayjs;
  workingEndDate: Dayjs | string | null;
  authFieldType: AuthFieldType;
};

const Shape = yup.object().shape({
  firstName: yup.string().required('Is required'),
  lastName: yup.string().required('Is required'),
  email: emailValidator,
  phone: phoneValidator,
  workingStartDate: yup.date().required('Is required'),
  workingEndDate: yup.date().nullable(),
});

const AddLocal = () => {
  const dispatch = useDispatch();

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [createStaff] = useStaffCreateMutation({});

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      workingStartDate: dayjs(),
      workingEndDate: '',
      authFieldType: AuthFieldType.PHONE,
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: () => {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        ...(values.email && { email: values.email }),
        clinicId: selectedClinic.id,
        authField:
          values.authFieldType === AuthFieldType.EMAIL ? values.email : values.phone,
        authFieldType: values.authFieldType,
        ...(values.email && { email: values.email }),
        workingStartDate: values.workingStartDate,
        ...(values.workingEndDate && { workingEndDate: values.workingEndDate }),
      };
      createStaff({
        ...data,
        position: Roles.LOCATION_MANAGER,
      })
        .unwrap()
        .then(res => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(
            setMessage({
              message: 'Local admin was successfully created',
              type: 'success',
            }),
          );
          resetForm();
        })
        .catch(error => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>Add Local Admin</S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="firstName"
                name="firstName"
                label="First name"
                isRequired
                value={values.firstName}
                onChange={handleChangeInput}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <Input
                id="lastName"
                name="lastName"
                label="Last name"
                isRequired
                value={values.lastName}
                onChange={handleChangeInput}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </S.InputRow>
            <S.InputRow className="radio">
              <RadioGroup
                value={values.authFieldType}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('authFieldType', (event.target as HTMLInputElement).value)
                }
              >
                <S.RadioItem>
                  <S.Label>
                    <S.LabelArticle>Registration type</S.LabelArticle>
                    <S.HelperText>
                      Please specify the type of Registration of this employee (which will
                      be further used by him/her for authorization in the app)
                    </S.HelperText>
                  </S.Label>
                  <S.RadioButtons>
                    <RadioButton label="Phone" value={AuthFieldType.PHONE} />
                    <RadioButton label="Email" value={AuthFieldType.EMAIL} />
                  </S.RadioButtons>
                </S.RadioItem>
              </RadioGroup>
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={values.phone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  id="phone"
                  name="phone"
                  label="Phone"
                  isRequired
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </ReactInputMask>
              <Input
                id="email"
                name="email"
                label="Email"
                isRequired
                value={values.email}
                onChange={handleChangeInput}
                error={!!errors.email}
                helperText={errors.email}
              />
            </S.InputRow>
            <S.InputRow>
              <DatePicker
                id="workingStartDate"
                name="workingStartDate"
                isRequired
                label="First work day"
                value={values.workingStartDate}
                onChange={value => {
                  setFieldError('workingStartDate', '');
                  setFieldValue('workingStartDate', value);
                }}
                error={!!errors.workingStartDate}
                helperText={errors.workingStartDate ? 'Not valid date format' : ''}
              />
              <DatePicker
                id="workingEndDate"
                name="workingEndDate"
                isRequired
                label="Last work day"
                value={values.workingEndDate}
                onChange={value => {
                  setFieldError('workingEndDate', '');
                  setFieldValue('workingEndDate', value);
                }}
                error={!!errors.workingEndDate}
                helperText={errors.workingEndDate ? 'Not valid date format' : ''}
              />
            </S.InputRow>
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Send Invitation" type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddLocal;
