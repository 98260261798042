import { useFormik } from 'formik';
import { Button, Input, SelectInput } from 'components';
import { CoveredServiceType, GroupNumbersInInsuranceType } from 'types/InsuranceType';
import * as S from './styles';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { ClinicInsuranceType } from 'types/enums/AppointmentEnum';
import CheckBoxWithLabel from 'components/atoms/CheckboxWithLabel';
import {
  useAddCoveredServiceMutation,
  useUpdateCoveredServiceMutation,
} from 'store/api/insuranceRelative/insuranceRelativeApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { useEffect } from 'react';
import { yupServicesSchema } from 'pages/SuperAdmin/components/InsuranceProfile/config';
import { useGetAllServicesQuery } from 'store/api/services/servicesApi';

type Props = {
  onClose: () => void;
  row?: CoveredServiceType | null;
  insuranceGroupNumber?: GroupNumbersInInsuranceType;
};

type FormValues = {
  typeServiceId: string | null;
  type: string;
  visitAuthorizet: number;
  visitUsed: number;
  isUnlimited: boolean;
};

export const AddCoveredServicesDialog = ({
  onClose,
  row,
  insuranceGroupNumber,
}: Props) => {
  const [addService] = useAddCoveredServiceMutation({});
  const [updateService] = useUpdateCoveredServiceMutation({});
  const dispatch = useAppDispatch();

  const listResult = useGetAllServicesQuery({
    take: 100,
    asc: 'DESC',
    page: 1,
  });

  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        typeServiceId: row?.typeServiceId,
        type: row.type,
        visitAuthorizet: row.visitAuthorizet,
        visitUsed: row.visitUsed,
        isUnlimited: row.isUnlimited,
      });
    }
  }, [row]);

  const formik = useFormik<FormValues>({
    initialValues: {
      typeServiceId: '',
      type: '',
      visitAuthorizet: 0,
      visitUsed: 0,
      isUnlimited: false,
    },
    validateOnChange: true,
    validationSchema: yupServicesSchema,
    onSubmit: () => {
      row
        ? updateService({
            id: row.id,
            typeServiceId: formik.values?.typeServiceId,
            isUnlimited: formik.values.isUnlimited,
            type: formik.values.type,
            visitAuthorizet: formik.values.isUnlimited
              ? 0
              : formik.values.visitAuthorizet,
            visitUsed: formik.values.isUnlimited ? 0 : formik.values.visitUsed,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Covered service was successfully updated',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              onClose();
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            })
        : addService({
            insuranceGroupNumberId: insuranceGroupNumber?.id,
            typeServiceId: formik.values?.typeServiceId,
            isUnlimited: formik.values.isUnlimited,
            type: formik.values.type,
            visitAuthorizet: formik.values.isUnlimited
              ? 0
              : formik.values.visitAuthorizet,
          })
            .unwrap()
            .then(res => {
              onClose();
              dispatch(
                setMessage({
                  message: 'Covered service was successfully created',
                  type: 'success',
                }),
              );
            })
            .catch(error => {
              onClose();
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
    },
  });
  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Content>
        <S.Article>{row ? 'Edit Covered Service ' : 'Add Covered Service'}</S.Article>
        <S.MainContent>
          <S.InputItems>
            <S.InputRow>
              <S.InputWrapper>
                <SelectInput
                  label="Choosed service"
                  isRequired
                  id={`typeServiceId`}
                  name={`typeServiceId`}
                  error={!!formik.errors.typeServiceId}
                  helperText={formik.errors.typeServiceId}
                  value={formik.values.typeServiceId}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('typeServiceId', '');
                    formik.handleChange(e);
                  }}
                >
                  {listResult?.currentData?.rows?.map(reason => (
                    <MenuItem
                      key={reason.id}
                      value={reason.id}
                      disabled={insuranceGroupNumber?.coveredServices?.some(
                        service => reason.id === service.typeServiceId,
                      )}
                    >
                      <S.MenuItemContent>{reason.serviceName}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectInput>
              </S.InputWrapper>

              <S.InputWrapper>
                <SelectInput
                  label="Type"
                  isRequired
                  id={`type`}
                  name={`type`}
                  error={!!formik.errors.type}
                  helperText={formik.errors.type}
                  value={formik.values.type}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError('type', '');
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value={ClinicInsuranceType.OUT_OF_NETWORK}>
                    <S.MenuItemContent>Out of network</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={ClinicInsuranceType.IN_NETWORK}>
                    <S.MenuItemContent>In network</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
            </S.InputRow>

            <S.InputRow>
              <S.InputWrapper>
                <Input
                  type={formik.values.isUnlimited ? 'text' : 'number'}
                  label="No. of Visit Authorized"
                  id="visitAuthorizet"
                  name="visitAuthorizet"
                  value={formik.values.isUnlimited ? 'UV' : formik.values.visitAuthorizet}
                  disabled={formik.values.isUnlimited}
                  error={
                    formik.values.isUnlimited
                      ? undefined
                      : !!formik.errors.visitAuthorizet
                  }
                  helperText={
                    formik.values.isUnlimited ? undefined : formik.errors.visitAuthorizet
                  }
                  onChange={e => formik.handleChange(e)}
                />
              </S.InputWrapper>

              {!!row && (
                <S.InputWrapper>
                  <Input
                    type={formik.values.isUnlimited ? 'text' : 'number'}
                    label="No. of Visit Used"
                    id="visitUsed"
                    name="visitUsed"
                    value={formik.values.isUnlimited ? 'UV' : formik.values.visitUsed}
                    disabled={formik.values.isUnlimited}
                    error={
                      formik.values.isUnlimited ? undefined : !!formik.errors.visitUsed
                    }
                    helperText={
                      formik.values.isUnlimited ? undefined : formik.errors.visitUsed
                    }
                    onChange={e => formik.handleChange(e)}
                  />
                </S.InputWrapper>
              )}
            </S.InputRow>
            <S.InputRow>
              <CheckBoxWithLabel
                isArticle
                label="Is unlimited"
                id="isUnlimited"
                name="isUnlimited"
                checked={formik.values.isUnlimited}
                value={formik.values.isUnlimited}
                onChange={e => formik.handleChange(e)}
              />
            </S.InputRow>
          </S.InputItems>
        </S.MainContent>
        <S.ButtonWrapper>
          <Button text={row ? 'Edit' : 'Add'} type="submit" />
        </S.ButtonWrapper>
      </S.Content>
    </form>
  );
};
